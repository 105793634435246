import axios from "axios";

export const getBaseUrl = () => {
  return process.env.REACT_APP_STAGE_NEURUN_URL;
}

const axiosInstance = axios.create({
    baseURL: `${getBaseUrl()}/api/open`,
    headers: {
        'Content-Type': 'application/json',
    },
})

axiosInstance.interceptors.request.use(
    function (request) {
        const token = localStorage.getItem('token')
        if (token) {
            request.headers.Authorization = 'Bearer ' + token
        }

        return request
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    },
)

axiosInstance.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        if (error.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('refresh_token')
            window.location.reload()
        }
        return Promise.reject(error)
    },
)

export default axiosInstance


